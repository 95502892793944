exports.components = {
  "component---src-components-post-posts-jsx-content-file-path-src-news-adventi-forgatag-2023-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/adventi-forgatag-2023.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-adventi-forgatag-2023-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-anniversary-elokert-atadas-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/anniversary/elokert-atadas.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-anniversary-elokert-atadas-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-anniversary-halacska-25-galaest-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/anniversary/halacska-25-galaest.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-anniversary-halacska-25-galaest-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-anniversary-halacska-25-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/anniversary/halacska-25.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-anniversary-halacska-25-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-anniversary-husegerme-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/anniversary/husegerme.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-anniversary-husegerme-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-anniversary-nemeth-90-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/anniversary/nemeth-90.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-anniversary-nemeth-90-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-budafoki-bornapok-2022-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/budafoki-bornapok-2022.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-budafoki-bornapok-2022-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-budafoki-bornapok-2023-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/budafoki-bornapok-2023.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-budafoki-bornapok-2023-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-concert-egykor-koncert-2023-06-17-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/concert/egykor-koncert-2023-06-17.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-concert-egykor-koncert-2023-06-17-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-concert-egykor-koncert-2023-10-07-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/concert/egykor-koncert-2023-10-07.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-concert-egykor-koncert-2023-10-07-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-concert-egykor-koncert-2024-06-02-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/concert/egykor-koncert-2024-06-02.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-concert-egykor-koncert-2024-06-02-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-concert-jotekonysagi-hangverseny-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/concert/jotekonysagi-hangverseny.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-concert-jotekonysagi-hangverseny-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-concert-lenart-linda-orgonahangverseny-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/concert/lenart-linda-orgonahangverseny.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-concert-lenart-linda-orgonahangverseny-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-concert-mozart-zenekar-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/concert/mozart-zenekar.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-concert-mozart-zenekar-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-concert-sursum-corda-koncertsorozat-2023-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/concert/sursum-corda-koncertsorozat-2023.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-concert-sursum-corda-koncertsorozat-2023-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-concert-sursum-corda-koncertsorozat-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/concert/sursum-corda-koncertsorozat.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-concert-sursum-corda-koncertsorozat-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-feast-advent-2023-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/feast/advent-2023.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-feast-advent-2023-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-feast-adventi-hajnali-zsoltaros-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/feast/adventi-hajnali-zsoltaros.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-feast-adventi-hajnali-zsoltaros-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-feast-adventi-koszoru-keszites-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/feast/adventi-koszoru-keszites.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-feast-adventi-koszoru-keszites-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-feast-adventi-koszorukeszites-2023-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/feast/adventi-koszorukeszites-2023.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-feast-adventi-koszorukeszites-2023-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-feast-adventi-workshop-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/feast/adventi-workshop.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-feast-adventi-workshop-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-feast-kantalas-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/feast/kantalas.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-feast-kantalas-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-feast-orbottyan-2022-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/feast/orbottyan-2022.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-feast-orbottyan-2022-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-feast-orbottyan-2023-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/feast/orbottyan-2023.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-feast-orbottyan-2023-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-forum-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/forum.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-forum-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-hazas-kor-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/hazas-kor.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-hazas-kor-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-karacsonyi-tortenet-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/karacsonyi-tortenet.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-karacsonyi-tortenet-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-kenu-tura-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/kenu-tura.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-kenu-tura-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-kirandulas-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/kirandulas.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-kirandulas-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-lecture-adjatok-vissza-a-vallasunkat-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/lecture/adjatok-vissza-a-vallasunkat.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-lecture-adjatok-vissza-a-vallasunkat-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-lecture-bogar-eloadas-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/lecture/bogar-eloadas.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-lecture-bogar-eloadas-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-lecture-eloadas-a-hunokrol-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/lecture/eloadas-a-hunokrol.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-lecture-eloadas-a-hunokrol-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-lecture-gyaszjelentes-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/lecture/gyaszjelentes.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-lecture-gyaszjelentes-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-lecture-kitelepites-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/lecture/kitelepites.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-lecture-kitelepites-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-lecture-program-sorozat-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/lecture/program-sorozat.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-lecture-program-sorozat-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-lecture-raday-napok-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/lecture/raday-napok.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-lecture-raday-napok-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-03-26-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-03-26.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-03-26-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-04-02-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-04-02.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-04-02-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-04-09-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-04-09.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-04-09-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-04-16-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-04-16.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-04-16-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-04-23-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-04-23.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-04-23-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-04-30-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-04-30.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-04-30-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-05-07-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-05-07.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-05-07-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-05-14-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-05-14.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-05-14-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-05-21-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-05-21.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-05-21-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-05-29-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-05-29.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-05-29-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-06-04-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-06-04.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-06-04-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-06-11-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-06-11.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-06-11-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-06-18-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-06-18.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-06-18-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-06-25-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-06-25.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-06-25-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-07-02-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-07-02.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-07-02-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-07-09-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-07-09.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-07-09-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-07-16-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-07-16.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-07-16-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-07-23-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-07-23.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-07-23-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-07-30-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-07-30.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-07-30-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-08-06-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-08-06.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-08-06-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-08-13-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-08-13.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-08-13-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-08-20-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-08-20.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-08-20-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-08-27-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-08-27.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-08-27-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-09-03-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-09-03.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-09-03-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-09-17-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-09-17.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-09-17-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-09-24-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-09-24.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-09-24-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-10-01-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-10-01.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-10-01-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-10-08-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-10-08.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-10-08-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-10-15-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-10-15.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-10-15-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-10-22-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-10-22.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-10-22-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-10-29-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-10-29.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-10-29-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-11-04-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-11-04.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-11-04-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-11-12-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-11-12.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-11-12-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-11-19-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-11-19.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-11-19-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-11-26-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-11-26.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-11-26-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-12-03-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-12-03.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-12-03-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-12-10-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-12-10.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-12-10-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-12-17-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-12-17.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-12-17-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-12-25-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-12-25.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-12-25-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-12-31-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2023-12-31.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2023-12-31-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-01-07-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-01-07.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-01-07-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-01-14-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-01-14.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-01-14-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-01-21-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-01-21.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-01-21-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-01-28-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-01-28.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-01-28-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-02-04-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-02-04.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-02-04-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-02-11-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-02-11.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-02-11-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-02-18-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-02-18.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-02-18-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-02-25-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-02-25.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-02-25-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-03-03-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-03-03.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-03-03-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-03-10-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-03-10.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-03-10-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-03-17-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-03-17.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-03-17-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-03-31-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-03-31.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-03-31-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-04-01-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-04-01.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-04-01-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-04-07-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-04-07.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-04-07-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-04-14-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-04-14.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-04-14-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-04-21-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-04-21.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-04-21-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-04-28-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-04-28.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-04-28-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-05-05-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-05-05.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-05-05-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-05-12-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-05-12.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-05-12-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-05-19-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-05-19.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-05-19-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-05-20-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-05-20.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-05-20-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-05-26-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-05-26.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-05-26-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-06-02-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-06-02.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-06-02-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-06-09-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-06-09.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-06-09-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-06-16-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-06-16.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-06-16-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-06-23-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-06-23.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-06-23-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-06-30-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-06-30.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-06-30-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-07-07-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-07-07.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-07-07-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-07-14-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-07-14.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-07-14-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-07-21-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-07-21.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-07-21-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-07-28-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-07-28.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-07-28-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-08-04-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-08-04.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-08-04-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-08-11-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-08-11.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-08-11-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-08-18-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-08-18.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-08-18-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-08-25-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-08-25.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-08-25-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-09-01-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-09-01.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-09-01-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-09-08-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-09-08.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-09-08-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-09-15-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-09-15.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-09-15-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-09-22-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-09-22.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-09-22-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-09-29-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-09-29.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-09-29-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-10-06-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/note/hirdetes-2024-10-06.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-note-hirdetes-2024-10-06-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-nyiltnapok-2023-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/nyiltnapok-2023.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-nyiltnapok-2023-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-pizzasutes-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/pizzasutes.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-pizzasutes-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-presbiter-valasztas-eredmeny-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/presbiter-valasztas-eredmeny.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-presbiter-valasztas-eredmeny-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-presbiter-valasztas-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/presbiter-valasztas.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-presbiter-valasztas-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-soul-ajtonyito-istentisztelet-2023-10-15-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/soul/ajtonyito-istentisztelet-2023-10-15.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-soul-ajtonyito-istentisztelet-2023-10-15-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-soul-ajtonyito-istentisztelet-2023-11-19-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/soul/ajtonyito-istentisztelet-2023-11-19.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-soul-ajtonyito-istentisztelet-2023-11-19-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-soul-ajtonyito-istentisztelet-2023-12-10-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/soul/ajtonyito-istentisztelet-2023-12-10.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-soul-ajtonyito-istentisztelet-2023-12-10-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-soul-ajtonyito-istentisztelet-2024-01-21-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/soul/ajtonyito-istentisztelet-2024-01-21.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-soul-ajtonyito-istentisztelet-2024-01-21-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-soul-aldozocsutortok-2023-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/soul/aldozocsutortok-2023.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-soul-aldozocsutortok-2023-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-soul-imaalkalom-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/soul/imaalkalom.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-soul-imaalkalom-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-soul-imadelutan-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/soul/imadelutan.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-soul-imadelutan-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-soul-keresztkerdesek-2022-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/soul/keresztkerdesek-2022.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-soul-keresztkerdesek-2022-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-soul-keresztkerdesek-2023-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/soul/keresztkerdesek-2023.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-soul-keresztkerdesek-2023-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-soul-okumenikus-imahet-2023-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/soul/okumenikus-imahet-2023.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-soul-okumenikus-imahet-2023-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-soul-okumenikus-istentisztelet-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/soul/okumenikus-istentisztelet.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-soul-okumenikus-istentisztelet-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-soul-oszi-ifjusagi-istentiszteletek-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/soul/oszi-ifjusagi-istentiszteletek.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-soul-oszi-ifjusagi-istentiszteletek-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-szekely-ortuz-2023-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/szekely-ortuz-2023.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-szekely-ortuz-2023-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-takaritas-2023-11-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/takaritas-2023-11.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-takaritas-2023-11-mdx" */),
  "component---src-components-post-posts-jsx-content-file-path-src-news-varva-varlak-mdx": () => import("./../../../src/components/post/Posts.jsx?__contentFilePath=N:/projects/vscode/web_projects/budafoki-reformatus-gyulekezet-calendar/src/news/varva-varlak.mdx" /* webpackChunkName: "component---src-components-post-posts-jsx-content-file-path-src-news-varva-varlak-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-connect-js": () => import("./../../../src/pages/connect.js" /* webpackChunkName: "component---src-pages-connect-js" */),
  "component---src-pages-event-js": () => import("./../../../src/pages/event.js" /* webpackChunkName: "component---src-pages-event-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-markdown-js": () => import("./../../../src/pages/markdown.js" /* webpackChunkName: "component---src-pages-markdown-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-memorial-js": () => import("./../../../src/pages/memorial.js" /* webpackChunkName: "component---src-pages-memorial-js" */),
  "component---src-pages-news-advent-js": () => import("./../../../src/pages/news/advent.js" /* webpackChunkName: "component---src-pages-news-advent-js" */),
  "component---src-pages-news-concert-js": () => import("./../../../src/pages/news/concert.js" /* webpackChunkName: "component---src-pages-news-concert-js" */),
  "component---src-pages-news-diocese-js": () => import("./../../../src/pages/news/diocese.js" /* webpackChunkName: "component---src-pages-news-diocese-js" */),
  "component---src-pages-news-district-js": () => import("./../../../src/pages/news/district.js" /* webpackChunkName: "component---src-pages-news-district-js" */),
  "component---src-pages-news-donation-js": () => import("./../../../src/pages/news/donation.js" /* webpackChunkName: "component---src-pages-news-donation-js" */),
  "component---src-pages-news-ecumenical-js": () => import("./../../../src/pages/news/ecumenical.js" /* webpackChunkName: "component---src-pages-news-ecumenical-js" */),
  "component---src-pages-news-gift-js": () => import("./../../../src/pages/news/gift.js" /* webpackChunkName: "component---src-pages-news-gift-js" */),
  "component---src-pages-news-gymnasium-js": () => import("./../../../src/pages/news/gymnasium.js" /* webpackChunkName: "component---src-pages-news-gymnasium-js" */),
  "component---src-pages-news-inaugurate-js": () => import("./../../../src/pages/news/inaugurate.js" /* webpackChunkName: "component---src-pages-news-inaugurate-js" */),
  "component---src-pages-news-interview-js": () => import("./../../../src/pages/news/interview.js" /* webpackChunkName: "component---src-pages-news-interview-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-news-music-js": () => import("./../../../src/pages/news/music.js" /* webpackChunkName: "component---src-pages-news-music-js" */),
  "component---src-pages-news-newsletters-js": () => import("./../../../src/pages/news/newsletters.js" /* webpackChunkName: "component---src-pages-news-newsletters-js" */),
  "component---src-pages-news-note-js": () => import("./../../../src/pages/news/note.js" /* webpackChunkName: "component---src-pages-news-note-js" */),
  "component---src-pages-news-occasion-js": () => import("./../../../src/pages/news/occasion.js" /* webpackChunkName: "component---src-pages-news-occasion-js" */),
  "component---src-pages-news-prize-js": () => import("./../../../src/pages/news/prize.js" /* webpackChunkName: "component---src-pages-news-prize-js" */),
  "component---src-pages-news-programs-js": () => import("./../../../src/pages/news/programs.js" /* webpackChunkName: "component---src-pages-news-programs-js" */),
  "component---src-pages-news-sorority-js": () => import("./../../../src/pages/news/sorority.js" /* webpackChunkName: "component---src-pages-news-sorority-js" */),
  "component---src-pages-news-soul-js": () => import("./../../../src/pages/news/soul.js" /* webpackChunkName: "component---src-pages-news-soul-js" */),
  "component---src-pages-news-winedays-js": () => import("./../../../src/pages/news/winedays.js" /* webpackChunkName: "component---src-pages-news-winedays-js" */),
  "component---src-pages-news-worship-js": () => import("./../../../src/pages/news/worship.js" /* webpackChunkName: "component---src-pages-news-worship-js" */),
  "component---src-pages-palyazatok-js": () => import("./../../../src/pages/palyazatok.js" /* webpackChunkName: "component---src-pages-palyazatok-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-streaming-js": () => import("./../../../src/pages/streaming.js" /* webpackChunkName: "component---src-pages-streaming-js" */),
  "component---src-pages-tablefootball-js": () => import("./../../../src/pages/tablefootball.js" /* webpackChunkName: "component---src-pages-tablefootball-js" */),
  "component---src-pages-tabletennis-js": () => import("./../../../src/pages/tabletennis.js" /* webpackChunkName: "component---src-pages-tabletennis-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

